// TODO: Figure this out, it causes a lot of style conflicts
a:not(.dark-exempt) {
    color: var(--primary-color);
    > i {
      color: var(--primary-color);
    }
  }

  a.btn {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

a.read-more-link {
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: var(--body-text-color) !important;

    &:hover {
      text-decoration: none;
    }
}

td > a:not(.dark-exempt) {
  color: var(--primary-color-darker-shade);
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
